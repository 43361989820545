import { Box, Button, Callout, H4, Stack, Text } from '@amboss/design-system';
import React from 'react';

interface EmptyStateProps {
  error?: boolean;
  empty?: boolean;
  retry: () => void;
}

const EmptyState: React.FC<EmptyStateProps> = ({ error, empty, retry }) => {
  if (error) {
    return (
      <Stack space="m">
        <H4>Unable to load the data</H4>
        <Text color="tertiary" size="s" align="center">
          We can&apos;t seem to be able to connect to the server. Please try again.
        </Text>
        <Text color="tertiary" size="s" align="center">
          If the problem persists, contact customer support.
        </Text>
        <Button
          leftIcon="reset"
          onClick={retry}
          size="m"
          type="button"
          variant="tertiary"
        >
          Try again
        </Button>
      </Stack>
    );
  } else if (empty) {
    return (
      <Stack alignItems="center" space="xs">
        <H4>No chats found</H4>
        <Text color="tertiary" size="s" align="center">
          We can&apos;t find any chats for this search and filter.
        </Text>
        <Box space={['s', 'l', 'xxl']} bSpace="zero" tSpace="l">
          <Callout
            type="warning"
            icon="alert-circle"
            text={
              <Text size="xs">
                Wenn du hier Chats vermisst, prüfe einmal deine Einstellungen zum
                Cross-Site-Tracking und Drittanbieter-Cookies!
              </Text>
            }
          />
        </Box>
      </Stack>
    );
  }
  return null;
};

export default EmptyState;
