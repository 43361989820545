import React, { useEffect, useState } from 'react';
import { Box, DataTable, Inline, Pagination, Stack, Text } from '@amboss/design-system';
import { Chat } from '../../../types/types';
import useChatColumns from '../../../hooks/useChatColumns';
import EmptyState from './EmptyState';

interface ChatListProps {
  activeChats: Chat[];
  onStartExam: (chatId: string | null) => void;
  loadingActiveChats: boolean;
  activeChatsError: string | null;
  deleteChat: (chatId: string) => void;
  fetchActiveChats: () => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}

const ChatList: React.FC<ChatListProps> = ({
  activeChats,
  onStartExam,
  loadingActiveChats,
  activeChatsError,
  deleteChat,
  fetchActiveChats,
  searchTerm,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;

  const handleFilter = (chat: Chat) => {
    return (
      chat.lastMessage?.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
      chat.firstMessage?.content.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const filteredChats: Chat[] = searchTerm
    ? activeChats.filter(handleFilter)
    : activeChats;

  const handlePrevClick = () => setCurrentPage(currentPage - 1);
  const handleNextClick = () => setCurrentPage(currentPage + 1);

  useEffect(() => {
    // Adjust the current page if we're beyond the available chats
    const maxPage = Math.ceil(filteredChats.length / itemsPerPage);
    if (currentPage > maxPage) {
      setCurrentPage(maxPage || 1);
    }
  }, [filteredChats, itemsPerPage, currentPage]);

  // Pagination logic
  const startIdx = (currentPage - 1) * itemsPerPage;
  const paginatedChats = filteredChats.slice(startIdx, startIdx + itemsPerPage);

  const CustomPagination: React.FC = () => {
    return (
      <Pagination
        numOfItems={filteredChats ? filteredChats.length : 0}
        numOfItemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPrevClick={handlePrevClick}
        onNextClick={handleNextClick}
      />
    );
  };

  return (
    <div>
      <Stack space={'s'}>
        <DataTable
          caption="Active Chats"
          columns={useChatColumns({ deleteChat, onStartExam }) || []}
          layout="auto"
          emptyTableContentHeight={'8rem'}
          footer={
            !loadingActiveChats && paginatedChats && paginatedChats.length ? (
              <Inline alignItems={'center'}>
                <CustomPagination />
              </Inline>
            ) : (
              <></>
            )
          }
          isLoading={loadingActiveChats}
          isEmpty={filteredChats.length === 0}
          rows={
            paginatedChats.map((chat) => ({
              id: chat.uuid,
              ...chat,
            })) || []
          }
        >
          {!loadingActiveChats && filteredChats.length === 0 && (
            <Box space={'m'} alignText="center">
              <EmptyState
                retry={fetchActiveChats}
                error={!activeChats || !!activeChatsError}
                empty={!activeChatsError && !filteredChats.length}
              />
            </Box>
          )}
        </DataTable>
      </Stack>
      {activeChatsError && (
        <Box bSpace="xs" tSpace="s" alignText="center">
          <Text color="error" align="center">
            {activeChatsError}
          </Text>
        </Box>
      )}
    </div>
  );
};

export default ChatList;
