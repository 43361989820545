import React from 'react';
import {
  PictogramButton,
  Link,
  Text,
  DataTableColumn,
  DataTableRow,
  TextClamped,
} from '@amboss/design-system';
import formatTimeString from '../utils/formatTimeString'; // Make sure this is the correct path

interface UseChatColumnsProps {
  deleteChat: (chatId: string) => void;
  onStartExam: (chatId: string | null) => void;
}

const useChatColumns = ({
  deleteChat,
  onStartExam,
}: UseChatColumnsProps): DataTableColumn[] => {
  return React.useMemo(() => {
    const columns: DataTableColumn[] = [
      {
        name: 'message',
        label: 'Message',
        align: 'left',
        isSortable: true,
        sortDirection: 'asc',
        width: 'auto',
        renderCell: (row: DataTableRow) => (
          <Link onClick={() => onStartExam(row.uuid)}>
            <TextClamped lines={2} align={'left'} as={'span'}>
              {row?.lastMessage?.content || 'No message'}
            </TextClamped>
          </Link>
        ),
      },
      {
        name: 'time',
        label: 'Time',
        align: 'left',
        isSortable: true,
        sortDirection: 'asc',
        width: '5rem',
        renderCell: (row: DataTableRow) => (
          <Text as="span" size={'m'}>
            {formatTimeString.formatTimeDifference(row?.lastMessage?.createdAt) || ''}
          </Text>
        ),
      },
      {
        name: 'delete',
        label: '',
        align: 'left',
        width: '1rem',
        renderCell: (row: DataTableRow) => (
          <PictogramButton icon="trash-2" onClick={() => deleteChat(row.uuid)} />
        ),
      },
    ];

    return columns;
  }, [deleteChat, onStartExam]);
};

export default useChatColumns;
