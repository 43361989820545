import React, { RefObject } from 'react';
import formatTimeString from '../../../utils/formatTimeString';
import { ChatMessage } from '../ChatMessage/ChatMessage';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { Box, Container, Inline, Link, Stack } from '@amboss/design-system';
import { Message } from '../../../types/types';

interface MessagesProps {
  messages: Message[];
  setMessages: (messages: Message[]) => void;
  isLoadingResponse: boolean;
  isLoadingHistory: boolean;
  seconds: number;
  setClockIsRunning: (prevState: (prevState: boolean) => boolean) => void;
  clockIsRunning: boolean;
  scrollToTop: () => void;
  setStartExam: (start: boolean) => void;
  messageListRef: RefObject<HTMLDivElement>;
  fetchChatHistory: () => void;
}

const Messages: React.FC<MessagesProps> = ({
  messages,
  setMessages,
  isLoadingResponse,
  isLoadingHistory,
  seconds,
  setClockIsRunning,
  clockIsRunning,
  scrollToTop,
  setStartExam,
  messageListRef,
  fetchChatHistory,
}) => {
  const isWideScreen = useWindowWidth();

  const handleDislike = async (messageId: string) => {
    try {
      const response = await fetch(`/api/chat/dislike`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Credentials: 'include',
        },
        body: JSON.stringify({ messageId }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setMessages(data.chatHistory || []);
    } catch (error) {
      console.error('Error disliking the message:', error);
      fetchChatHistory();
    }
  };

  return (
    <div className="message-list" ref={messageListRef}>
      <div className="chat-header">
        <Container elevation={2}>
          <Box space={['m', 'l', 'l']}>
            <Inline space={['m', 'l', 'xl']} noWrap>
              {messages.length > 0 && isWideScreen && (
                <Link onClick={scrollToTop}>Examen hat begonnen</Link>
              )}
              <Link
                color={
                  clockIsRunning && !isLoadingResponse && messages.length > 0
                    ? undefined
                    : 'warning'
                }
                onClick={() => setClockIsRunning((prevState: boolean) => !prevState)}
              >
                {('0' + Math.floor(seconds / 60)).slice(-2)}:
                {('0' + (seconds % 60)).slice(-2)}
              </Link>
              <Link onClick={() => setStartExam(false)}>Zurück</Link>
            </Inline>
          </Box>
        </Container>
      </div>
      <Stack alignItems="center">
        {messages.length > 0 && (
          <Stack>
            {messages.map(
              (message, index) =>
                index !== 0 && (
                  <ChatMessage
                    key={message._id}
                    type={message.role === 'assistant' ? 'assistant' : 'user'}
                    text={message.content}
                    timestamp={formatTimeString.formatDate(message.createdAt)}
                    additionalPictogram={
                      message.role === 'assistant'
                        ? {
                            icon: 'thumbs-down',
                            onClick: () => handleDislike(message._id),
                            checked: message.disliked,
                          }
                        : undefined
                    }
                  />
                )
            )}
            {isLoadingResponse &&
              messages.length > 0 &&
              messages[messages.length - 1].role !== 'assistant' && (
                <ChatMessage
                  type={'assistant'}
                  text={''}
                  timestamp={''}
                  isTyping={true}
                />
              )}
          </Stack>
        )}
        {isLoadingHistory && <div className="loader"></div>}
      </Stack>
    </div>
  );
};

export default Messages;
