import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import '../../styles/css/ChatPage.scss';
import axios, { CancelTokenSource } from 'axios';
import useAdjustHeight from '../../hooks/useAdjustHeight';
import MessageInput from './MessageInput';
import Messages from './Messages/Messages';
import useChatDuration from '../../hooks/useChatDuration';
import { Message } from '../../types/types';

interface ChatPageProps {
  initialMessage: string;
  setInitialMessage: (message: string) => void;
  selectedChatUuid?: string;
  setSelectedChatUuid: (uuid: string) => void;
  setStartExam: () => void;
  betaModel: boolean;
}

const ChatPage: React.FC<ChatPageProps> = ({
  initialMessage,
  setInitialMessage,
  selectedChatUuid,
  setSelectedChatUuid,
  setStartExam,
  betaModel,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(
    null
  );
  const abortControllerRef = useRef<AbortController>(null);

  const [liveTyping, setLiveTyping] = useState<string>('');

  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  const [isAtBottom, setIsAtBottom] = useState(true);

  const messageListRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const { seconds, setSeconds, clockIsRunning, setClockIsRunning } = useChatDuration({
    selectedChatUuid,
    isLoadingResponse,
    waiting: messages.length === 0,
  });

  useAdjustHeight('.chat-page');

  const fetchChatHistory = async () => {
    if (selectedChatUuid) {
      setIsLoadingHistory(true);
      try {
        const response = await axios.get(`/api/chat/history/${selectedChatUuid}`, {
          withCredentials: true,
        });
        setMessages(response.data.chatHistory || []);
      } catch (error) {
        console.error('Error fetching chat history:', error);
      } finally {
        setIsLoadingHistory(false);
      }
    }
  };

  // Fetch chat history when the component mounts or chatUuid changes
  useEffect(() => {
    fetchChatHistory().then(() => scrollToBottom());

    if (selectedChatUuid) {
      window.history.pushState({}, '', `/exam/${selectedChatUuid}`);
    } else {
      window.history.pushState({}, '', '/exam/new');
    }
  }, [selectedChatUuid]);

  const handleCancel = async () => {
    if (messages.length === 1) {
      setMessages([]);
    } else {
      setMessages((prevMessages) => {
        // Mark the last message as 'cancelled'
        return prevMessages.map((msg, index) =>
          index === prevMessages.length - 1 ? { ...msg, status: 'cancelled' } : msg
        );
      });
    }

    if (cancelTokenSource) {
      cancelTokenSource.cancel('Request canceled by user.');
    }

    try {
      // Make an API call to cancel the chat
      await axios.post(`/api/chat/cancel-chat/${selectedChatUuid}`, {
        withCredentials: true,
      });
      console.log('Chat canceled successfully.');
    } catch (error) {
      console.error('Error canceling the chat:', error);
    }
  };

  const handleAbort = async () => {
    if (messages.length === 1) {
      setMessages([]);
    } else {
      setMessages((prevMessages) => {
        // Mark the last message as 'cancelled'
        return prevMessages.map((msg, index) =>
          index === prevMessages.length - 1 ? { ...msg, status: 'cancelled' } : msg
        );
      });
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    try {
      // Make an API call to cancel the chat
      await axios.post(`/api/chat/cancel-chat/${selectedChatUuid}`, {
        withCredentials: true,
      });
      console.log('Chat canceled successfully.');
    } catch (error) {
      console.error('Error canceling the chat:', error);
    }
  };

  const scrollToTop = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = 15;
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messageListRef.current) {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  // Check if user is at the bottom of the message list
  const checkScrollBottom = () => {
    if (!messageListRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;
    const atBottom = scrollTop + clientHeight >= scrollHeight - 10; // 10px tolerance
    setIsAtBottom(atBottom);
  };

  // Set up scroll listener
  useLayoutEffect(() => {
    const messageListElement = messageListRef.current;
    if (messageListElement) {
      messageListElement.addEventListener('scroll', checkScrollBottom);
      checkScrollBottom(); // Initial check
      return () => {
        messageListElement.removeEventListener('scroll', checkScrollBottom);
      };
    }
  }, []);

  return (
    <div className="chat-page">
      <Messages
        messages={messages}
        setMessages={setMessages}
        isLoadingResponse={isLoadingResponse}
        isLoadingHistory={isLoadingHistory}
        seconds={seconds}
        clockIsRunning={clockIsRunning}
        setClockIsRunning={setClockIsRunning}
        scrollToTop={scrollToTop}
        setStartExam={setStartExam}
        messageListRef={messageListRef}
        fetchChatHistory={fetchChatHistory}
      />
      <MessageInput
        abortControllerRef={abortControllerRef}
        handleAbort={handleAbort}
        initialMessage={initialMessage}
        messages={messages}
        setMessages={setMessages}
        selectedChatUuid={selectedChatUuid}
        setSelectedChatUuid={setSelectedChatUuid}
        isLoadingResponse={isLoadingResponse}
        setIsLoadingResponse={setIsLoadingResponse}
        isAtBottom={isAtBottom}
        scrollToBottom={scrollToBottom}
        betaModel={betaModel}
      />
    </div>
  );
};

export default ChatPage;
