import React, { useContext, useEffect, useState } from 'react';
import LandingPage from './components/LandingPage/LandingPage';
import ChatPage from './components/ChatPage/ChatPage';
import { WebSocketContext } from './context/WebSocketContext';
import { ThemeProvider, dark, light, Container } from '@amboss/design-system';
import UserContext from './context/UserContext';

function App() {
  const ws = useContext(WebSocketContext);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('isDarkMode');
    return savedMode !== null
      ? JSON.parse(savedMode)
      : window.matchMedia('(prefers-color-scheme: dark)');
  });

  const [startExam, setStartExam] = useState(false);
  const [startInterview, setStartInterview] = useState(false);
  const [initialMessage, setInitialMessage] = useState('');
  const [selectedChatUuid, setSelectedChatUuid] = useState(null);
  const [betaModel, setBetaModel] = useState(() => {
    const value = localStorage.getItem('betaModel');
    return value !== null ? JSON.parse(value) : true;
  });
  const [currentArticle, setCurrentArticle] = useState({});
  const { setMediCoachUser } = useContext(UserContext);

  function updateMetaTags(title, description, keywords) {
    document.title = title;
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', description);
    document.querySelector('meta[name="keywords"]').setAttribute('content', keywords);
  }

  useEffect(() => {
    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  useEffect(() => {
    const receiveMessage = (event) => {
      // eslint-disable-next-line no-constant-condition
      if (true) {
        const { type, data } = event.data;

        switch (type) {
          case 'article':
            setCurrentArticle(data);
            break;
          case 'mediCoachUser':
            setMediCoachUser(data);
            break;
          case 'isDarkmodeActive':
            console.log('Received isDarkmodeActive:', data);
            setIsDarkMode(data);
            break;
          default:
            console.error('Unknown message type received:', type);
        }
      }
    };

    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  // When article changes, request question Json from backend and write to initialMessage
  // article has {eid, title}
  useEffect(() => {
    if (currentArticle.eid) {
      fetch(`/api/qbank/${currentArticle.eid}`)
        .then((response) => response.json())
        .then((data) => {
          setInitialMessage(JSON.stringify(data));
          onStartExam(null);
        })
        .catch((error) => {
          console.error('Error fetching article:', error);
        });
    }
  }, [currentArticle]);

  useEffect(() => {
    document.body.className = isDarkMode ? 'dark-theme' : 'light-theme';
  }, [isDarkMode]);

  // Add click  event listener and Remove on cleanup
  useEffect(() => {
    // Function to handle click event
    const handleClick = () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        // Send a message to the WebSocket server
        ws.send(JSON.stringify({ type: 'text', data: 'Clicked' }));
      }
    };
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [ws]);

  // Update local storage whenever betaModel changes
  useEffect(() => {
    localStorage.setItem('betaModel', JSON.stringify(betaModel));
  }, [betaModel]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'betaModel') {
        setBetaModel(JSON.parse(e.newValue));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (startExam) {
      updateMetaTags(
        'M3di-Coach - Examenssimulation',
        'Teilnehmen an einer interaktiven M3-Medizinexamenssimulation mit unserem KI-Chatbot.',
        'M3 Examen, Medizinstudium, KI-Chatbot, Prüfungsvorbereitung'
      );
    } else {
      window.history.pushState({}, '', '/');
      updateMetaTags(
        'M3di-Coach - Dein KI-Prüfer fürs M3',
        'M3di-Coach ist ein KI-unterstützter Chatbot, der Medizinstudenten bei der Vorbereitung auf das M3-Medizinexamen unterstützt.',
        'M3 Prüfung, Medizin Examen, KI-Chatbot, Prüfungsvorbereitung'
      );
    }
  }, [startExam]);

  const onStartExam = async (uuid) => {
    setSelectedChatUuid(uuid);
    setStartExam(true);

    if (uuid) {
      window.history.pushState({}, '', `/exam/${uuid}`);
    } else {
      window.history.pushState({}, '', '/exam/new');
    }
  };

  const onStartInterview = async (uuid) => {
    setSelectedChatUuid(uuid);
    setStartInterview(true);

    if (uuid) {
      window.history.pushState({}, '', `/interview/${uuid}`);
    } else {
      window.history.pushState({}, '', '/interview/new');
    }
  };

  useEffect(() => {
    function isValidJson(json) {
      return (
        Object.prototype.hasOwnProperty.call(json, 'question') &&
        Object.prototype.hasOwnProperty.call(json, 'answers') &&
        Array.isArray(json.answers) &&
        json.answers.every(
          (answer) =>
            Object.prototype.hasOwnProperty.call(answer, 'option') &&
            Object.prototype.hasOwnProperty.call(answer, 'explanation')
        )
      );
    }

    function receiveMessage(event) {
      // Check the origin of the data
      if (event.origin !== 'https://next.amboss.com') return;

      try {
        const data = JSON.parse(event.data);
        if (isValidJson(data)) {
          setInitialMessage(event.data);
        }
      } catch (error) {
        console.error('Invalid JSON format', error);
      }
    }

    window.addEventListener('message', receiveMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  return (
    <ThemeProvider theme={isDarkMode ? dark : light}>
      <Container type={''} elevation={0} squareCorners={true} overflow={'auto'}>
        <div className="App" aria-label="App">
          {startExam ? (
            <ChatPage
              initialMessage={initialMessage}
              setInitialMessage={setInitialMessage}
              selectedChatUuid={selectedChatUuid}
              setSelectedChatUuid={setSelectedChatUuid}
              setStartExam={setStartExam}
              setBetaModel={setBetaModel}
              betaModel={betaModel}
            />
          ) : (
            <LandingPage
              onStartExam={onStartExam}
              onStartInterview={onStartInterview}
              setBetaModel={setBetaModel}
              betaModel={betaModel}
              currentArticle={currentArticle}
              isDarkMode={isDarkMode}
              setIsDarkMode={setIsDarkMode}
            />
          )}
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default App;
