import React, { ReactNode, useState } from 'react';
import { MediCoachUser } from '../types/types';

interface UserContextType {
  mediCoachUser: MediCoachUser;
  setMediCoachUser: React.Dispatch<React.SetStateAction<MediCoachUser>>;
}

interface UserProviderProps {
  children: ReactNode;
}

const UserContext = React.createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [mediCoachUser, setMediCoachUser] = useState<MediCoachUser>({});

  const value: UserContextType = { mediCoachUser, setMediCoachUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;
