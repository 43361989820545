import React, { useEffect, useState } from 'react';
import logo from '../../assets/logo512.png';
import ChatList from './ChatList/ChatList';
import {
  Box,
  Column,
  Columns,
  Container,
  Icon,
  Image,
  Inline,
  Stack,
  Toggle,
} from '@amboss/design-system';
import DataTableFilter from './ChatList/DataTableFilter';
import useWindowWidth from '../../hooks/useWindowWidth';
import { Chat } from '../../types/types';

interface LandingPageProps {
  onStartExam: (uuid: string | null) => void;
  currentArticle: any;
  isDarkMode: boolean;
  setIsDarkMode: (prevState: (prevState: boolean) => boolean) => void;
}

const LandingPage: React.FC<LandingPageProps> = ({
  onStartExam,
  currentArticle,
  isDarkMode,
  setIsDarkMode,
}) => {
  const [activeChats, setActiveChats] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingActiveChats, setLoadingActiveChats] = useState(false);
  const [activeChatsError, setActiveChatsError] = useState(null);
  const isWideScreen = useWindowWidth(380);

  const fetchActiveChats = async () => {
    setLoadingActiveChats(true);
    setActiveChatsError(null);
    try {
      const response = await fetch('/api/chat/active-chats', {
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Network response was not ok.');
      const data = await response.json();
      setActiveChats(data.activeChats.filter((chat: Chat) => !chat.isDeleted));
    } catch (error: any) {
      // Replace 'any' if you have a more specific error type
      console.error('Error fetching active chats:', error.message);
      setActiveChatsError(error.message);
    } finally {
      setLoadingActiveChats(false);
    }
  };

  const deleteChat = async (uuid: string) => {
    try {
      const response = await fetch(`/api/chat/active-chats/delete/${uuid}`, {
        method: 'POST',
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Network response was not ok.');
      await fetchActiveChats();
    } catch (error: any) {
      // Replace 'any' if you have a more specific error type
      console.error('Error deleting chat:', error);
    }
  };

  useEffect(() => {
    // Fetch active chats when the component mounts
    fetchActiveChats().catch((err) => console.error(err.message));
  }, []);

  useEffect(() => {
    const adjustHeight = () => {
      const element = document.querySelector('.landing-container') as HTMLElement;
      if (element) {
        element.style.height = window.innerHeight + 'px';
      }
    };

    // Initial height adjustment
    adjustHeight();

    // Adjust height on resize or orientation change
    window.addEventListener('resize', adjustHeight);
    window.addEventListener('orientationchange', adjustHeight);

    // Cleanup event listeners
    return () => {
      window.removeEventListener('resize', adjustHeight);
      window.removeEventListener('orientationchange', adjustHeight);
    };
  }, [activeChats]);

  const Header = () => (
    <>
      <Column size={isWideScreen ? 'narrow' : 12}>
        <Inline alignItems={isWideScreen ? 'left' : 'center'}>
          <Image
            src={logo}
            alt="Amboss Logo"
            style={{ width: '75px' }}
            position={'center'}
          />
        </Inline>
      </Column>

      <Column size={isWideScreen ? 'fill' : 12} alignSelf={'end'}>
        <Stack alignItems="stretch" space="m">
          {isWideScreen && (
            <Toggle
              alignItems="right"
              // @ts-expect-error Using Icon as a label
              label={
                <div style={{ transform: 'translatex(8px)' }}>
                  <Icon name="bulb" />
                </div>
              }
              name="dark-mode-toggle"
              onChange={() => setIsDarkMode((prevState) => !prevState)}
              size="m"
              value="dark-mode-toggle"
              checked={isDarkMode}
            />
          )}
          <Inline alignItems={'right'}>
            <DataTableFilter
              filterText={searchTerm}
              onFilter={setSearchTerm}
              onClear={() => setSearchTerm('')}
            />
          </Inline>
        </Stack>
      </Column>
    </>
  );

  return (
    <Stack space="m">
      <Box space="xs">
        <Container elevation={2}>
          <Box space={['xxs', 'm', 'l']}>
            <Stack alignItems="stretch" space={['m', 'l', 'l']}>
              <div>
                {/* Depending on width render Inline vs. Stack */}
                <Columns
                  alwaysFillSpace
                  vAlignItems={'bottom'}
                  alignItems={'right'}
                  gap={'m'}
                >
                  <Header />
                </Columns>
              </div>
              <ChatList
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                activeChats={activeChats}
                onStartExam={onStartExam}
                loadingActiveChats={loadingActiveChats}
                activeChatsError={activeChatsError}
                deleteChat={deleteChat}
                fetchActiveChats={fetchActiveChats}
              />
            </Stack>
          </Box>
        </Container>
      </Box>
    </Stack>
  );
};

export default LandingPage;
