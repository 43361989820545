import { useState, useEffect } from 'react';

const useWindowWidth = (breakpoint = 400) => {
  const [isWide, setIsWide] = useState(window.innerWidth >= breakpoint);

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth >= breakpoint);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return isWide;
};

export default useWindowWidth;
