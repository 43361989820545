import React, { useEffect, useState } from 'react';
import { SearchInput } from '@amboss/design-system';

interface DataTableFilterProps {
  filterText: string;
  onFilter: (newValue: string) => void;
  onClear: () => void;
}

const DataTableFilter: React.FC<DataTableFilterProps> = ({
  filterText,
  onFilter,
  onClear,
}) => {
  const [wrapperWidth, setWrapperWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const element: HTMLElement = document.querySelector('body') as HTMLElement;
      if (element) {
        setWrapperWidth(element.offsetWidth);
      }
    };

    // Initial call
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const searchWrapperWidth = `${Math.min(wrapperWidth - 50, 250)}px`;

  return (
    <div className={'inventory__search-wrapper'} style={{ width: searchWrapperWidth }}>
      <SearchInput
        name="search"
        value={filterText}
        onChange={(e) => onFilter(e.target.value)}
        onClear={onClear}
        placeholder="Search..."
      />
    </div>
  );
};

export default DataTableFilter;
