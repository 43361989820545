import React, { useState } from 'react';
import styled from '@emotion/styled';
import '../../../styles/css/ChatMessage.scss';

import {
  Text,
  Icon,
  Badge,
  Stack,
  Box,
  Inline,
  Column,
  Columns,
  PictogramButton,
  Popover,
  Tooltip,
} from '@amboss/design-system';
import type { IconName } from '@amboss/design-system';

interface FlexPictogramButtonProps {
  icon: IconName;
  onClick: () => void;
  checked?: boolean;
}

interface ChatMessageProps {
  text: string;
  timestamp?: string;
  avatar?: IconName;
  type: 'user' | 'assistant';
  isTyping?: boolean;
  additionalPictogram?: FlexPictogramButtonProps;
  children?: React.ReactNode[];
}

const ChatContainer = styled.div<{ type: 'user' | 'assistant'; isHovered: boolean }>`
  ${({ theme, type, isHovered }) => `
    display: flex;
    flexDirection: ${type === 'assistant' ? 'row' : 'row-reverse'};
    justifyContent: flex-start;
    // width: 100%;
    margin: 8px 0;
    gap: 10px;
    justify-content: ${type === 'user' ? 'flex-end' : 'flex-start'};
    float: ${type === 'user' ? 'right' : 'left'};
    transition: opacity 0.2s ease-in, visibility 0.2s;
    
    ${
      isHovered &&
      `
      opacity: 1;
      visibility: visible;
      transition: opacity 0.8s ease-out, visibility 0.8s;
    `
    }
    
    @media (hover: none), (max-width: 560px) {
      opacity: 1;
      visibility: visible;
    }
  `}
`;

const ButtonWrapper = styled.div<{ isHovered: boolean }>`
  ${({ isHovered }) => `
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in, visibility 0.2s;

    ${
      isHovered &&
      `
      opacity: 1;
      visibility: visible;
      transition: opacity 0.8s ease-out, visibility 0.8s;
    `
    }
    
    @media (max-width: 468px) {
      display: none;
    }
    
    @media (hover: none), (max-width: 468px) {
      display: none;
    }
  `}
`;

const MessageBubble = styled.div<{ type: 'user' | 'assistant'; isTyping?: boolean }>`
  background-color: ${({ type }) =>
    type === 'user' ? '#1f6ce0' : 'var(--color-background-secondary-default)'};
  color: ${({ type }) =>
    type === 'user'
      ? 'var(--color-text-onAccent-default)'
      : 'var(--color-text-secondary-default)'};
  min-width: ${({ isTyping }) => (isTyping ? 'fit-content' : 'fit-content')};
  max-width: 100%;
`;

const FlexPictogramButton: React.FC<FlexPictogramButtonProps> = ({
  icon = 'thumbs-down',
  checked = false,
  onClick,
}) => (
  <PictogramButton
    ariaAttributes={{
      'aria-label': icon,
    }}
    icon={icon}
    onClick={onClick}
    size="xs"
    type="button"
    // @ts-expect-error Property value not indexed
    variant={checked ? 'primary' : undefined}
  />
);

// Define a CopyButton as PictogramButton that copies the text to the clipboard onClick
const CopyButton: React.FC<{ text: string }> = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => setIsCopied(true))
      .finally(() => setTimeout(() => setIsCopied(false), 1500));
  };

  return isCopied ? (
    <Tooltip placement="auto" content="Kopiert!">
      <PictogramButton
        ariaAttributes={{
          'aria-label': 'Copy',
        }}
        icon="copy"
        onClick={copyToClipboard}
        size="xs"
        type="button"
      />
    </Tooltip>
  ) : (
    <PictogramButton
      ariaAttributes={{
        'aria-label': 'Copy',
      }}
      icon="copy"
      onClick={copyToClipboard}
      size="xs"
      type="button"
    />
  );
};

export function ChatMessage(props: ChatMessageProps): React.ReactElement {
  const { text, timestamp, avatar } = props;
  const [isHovered, setIsHovered] = React.useState(false);

  const UserIcon: JSX.Element = (
    <Icon
      name="user"
      size="s"
      color="secondary"
      // @ts-expect-error transparent backgroundColor is not part of the IconProps but works for this use case
      backgroundColor="transparent"
    />
  );
  const AssistantIcon: JSX.Element = (
    <Icon
      name="education"
      size="s"
      // @ts-expect-error Property value not indexed
      color="onAccent"
      backgroundColor="purple"
    />
  );

  // Component for User Chat Message
  if (props?.type === 'user') {
    const { type, additionalPictogram } = props;

    return (
      <ChatContainer
        type={type}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isHovered={isHovered}
        data-ds-id="ChatMessage"
      >
        {avatar && UserIcon}
        <Stack space="xxs" alignItems="right">
          <Columns gap={['m', 'l', 'l']} vAlignItems="center" alignItems="right">
            <Column size="auto" alignSelf="start" order="last">
              <MessageBubble type={type}>
                <Text
                  // @ts-expect-error Property value not indexed
                  color="onAccent"
                >
                  {text}
                </Text>
              </MessageBubble>
            </Column>
            {additionalPictogram && (
              <ButtonWrapper isHovered={isHovered}>
                <Column size="narrow" alignSelf="center" order="first">
                  <Inline space="m" vAlignItems="center" noWrap>
                    <FlexPictogramButton
                      icon={additionalPictogram.icon}
                      onClick={additionalPictogram.onClick}
                      checked={additionalPictogram.checked}
                    />
                  </Inline>
                </Column>
              </ButtonWrapper>
            )}
          </Columns>
          {timestamp && (
            <Box space="zero" lSpace="m" rSpace="m">
              <Text as="span" size="xs" color="secondary">
                {timestamp}
              </Text>
            </Box>
          )}
        </Stack>
      </ChatContainer>
    );
  }

  if (props?.type === 'assistant') {
    const { type, isTyping, children, additionalPictogram } = props;

    return (
      <ChatContainer
        type={type}
        data-ds-id="ChatMessage"
        isHovered={isHovered}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {avatar && AssistantIcon}
        <Stack space="xxs" alignItems="left">
          <Columns gap={['m', 'l', 'l']} vAlignItems="center" alignItems="left">
            <Column size="auto" alignSelf="start" order="first">
              <MessageBubble type={type} isTyping={isTyping}>
                {isTyping && (
                  <Badge
                    // @ts-expect-error Badge should not take Element as text
                    text={
                      <Text as="span" weight="bold">
                        ...
                      </Text>
                    }
                    color="gray"
                  />
                )}
                {!isTyping && <Text color="primary">{text}</Text>}
              </MessageBubble>
            </Column>
            {!isTyping && (
              <ButtonWrapper isHovered={isHovered}>
                <Column size="narrow" alignSelf="center" order="last">
                  <Inline space="m" vAlignItems="center" noWrap>
                    <CopyButton text={text} />
                    {additionalPictogram && (
                      <FlexPictogramButton
                        icon={additionalPictogram.icon}
                        onClick={additionalPictogram.onClick}
                        checked={additionalPictogram.checked}
                      />
                    )}
                  </Inline>
                </Column>
              </ButtonWrapper>
            )}
          </Columns>
          {timestamp && !isTyping && !children && (
            <Box space="zero" lSpace="m" rSpace="m">
              <Text as="span" size="xs" color="secondary">
                {timestamp}
              </Text>
            </Box>
          )}
          {children && (
            <Box space="xs" lSpace="m" rSpace="m">
              <Inline space="m">{children}</Inline>
            </Box>
          )}
        </Stack>
      </ChatContainer>
    );
  } else {
    return <></>;
  }
}
