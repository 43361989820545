import React, { createContext, useEffect, useRef, useState } from 'react';

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [ws, setWs] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [isProcessingRecording, setIsProcessingRecording] = useState(false);

  const reconnectInterval = useRef(null); // Store the interval ID for reconnection attempts

  useEffect(() => {
    const connectWebSocket = () => {
      const wsUrl =
        process.env.NODE_ENV === 'production'
          ? 'wss://m3ai.kniggi.com'
          : 'ws://localhost:3001';

      const newWs = new WebSocket(wsUrl);
      setWs(newWs);

      newWs.onopen = () => {
        console.log('WebSocket Client Connected at ' + wsUrl);
        clearInterval(reconnectInterval.current); // Clear reconnection interval upon successful connection
        reconnectInterval.current = null; // Reset the interval ref
      };

      newWs.onmessage = (e) => {
        const message = JSON.parse(e.data);
        if (message && message.type === 'transcript') {
          // Handle the transcription result
          if (message.message) setNewMessage(message.message.text);
          setIsProcessingRecording(false);
        }
      };

      newWs.onclose = () => {
        console.log('WebSocket Client Disconnected');
        setWs(null);
        startReconnect(); // Start attempting to reconnect
      };

      newWs.onerror = (error) => {
        console.error('WebSocket Error:', error);
        newWs.close(); // Close the WebSocket on error
      };
    };

    const startReconnect = () => {
      if (!reconnectInterval.current) {
        reconnectInterval.current = setInterval(() => {
          console.log('Attempting to reconnect...');
          connectWebSocket();
        }, 5000);
      }
    };

    connectWebSocket();

    return () => {
      clearInterval(reconnectInterval.current); // Clear interval on unmount
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, []);

  return (
    <WebSocketContext.Provider
      value={{
        ws,
        newMessage,
        setNewMessage,
        isProcessingRecording,
        setIsProcessingRecording,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
