function formatTimeDifference(createdAt) {
  const currentTime = new Date();
  const messageTime = new Date(createdAt);
  const timeDiff = currentTime - messageTime;

  const minutes = Math.floor(timeDiff / 60000);
  const hours = Math.floor(timeDiff / 3600000);
  const days = Math.floor(timeDiff / 86400000);

  const prefix = window.innerWidth > 480 ? 'vor ' : '';

  if (days > 365) {
    return '> 1 Jahr';
  } else if (days > 30) {
    return '> 1 Monat';
  } else if (days > 7) {
    return '> 1 Woche';
  } else if (days > 0) {
    return `${prefix}${days} Tag${days > 1 ? (prefix.length > 0 ? 'en' : 'e') : ''}`;
  } else if (hours > 0) {
    return `${prefix}${hours} Std.`;
  } else if (minutes > 1) {
    return `${prefix}${minutes} Min.`;
  } else {
    return prefix.length > 0 ? 'zuletzt' : 'jetzt';
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const options = { hour: '2-digit', minute: '2-digit' };

  // If the date is today, return only the time
  if (date.toDateString() === now.toDateString()) {
    return date.toLocaleTimeString([], options);
  }

  // Otherwise, return the date in a short format
  return date.toLocaleDateString();
}

module.exports = {
  formatTimeDifference,
  formatDate,
};
