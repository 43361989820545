import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

interface UseChatDurationProps {
  selectedChatUuid?: string;
  isLoadingResponse: boolean;
  waiting: boolean;
}

const useChatDuration = ({
  selectedChatUuid,
  isLoadingResponse,
  waiting,
}: UseChatDurationProps) => {
  const [clockIsRunning, setClockIsRunning] = useState<boolean>(true);
  const [seconds, setSeconds] = useState<number>(0);
  const secondsRef = useRef<number>(0);
  const hasFetchedRef = useRef(false);

  useEffect(() => {
    secondsRef.current = seconds;
  }, [seconds]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setClockIsRunning(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    const interval = setInterval(() => {
      if (clockIsRunning && !isLoadingResponse && !waiting) {
        setSeconds((seconds) => seconds + 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [clockIsRunning, isLoadingResponse, waiting]);

  useEffect(() => {
    const readChatDuration = async () => {
      try {
        const response = await fetch(`/api/chat/duration/${selectedChatUuid}`);
        if (!response.ok) throw new Error('Network response was not ok.');
        const data = await response.json();
        setSeconds(data.duration);
        hasFetchedRef.current = true;
      } catch (error) {
        console.error('Error reading chat duration:', error);
      }
    };

    const writeChatDuration = async () => {
      try {
        const response = await fetch(`/api/chat/duration/${selectedChatUuid}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ duration: secondsRef.current }),
        });
        if (!response.ok) throw new Error('Network response was not ok.');
        console.log('Chat duration updated successfully.');
      } catch (error) {
        console.error('Error writing chat duration:', error);
      }
    };

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      writeChatDuration();
      // Required for Chrome to trigger the event
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    readChatDuration().catch((err) => console.error(err.message));

    return () => {
      // Remove event listener and write chat duration when the component unmounts
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (hasFetchedRef.current && secondsRef.current > 0) {
        writeChatDuration().catch((err) => console.error(err.message));
      }
    };
  }, [selectedChatUuid]);

  return { seconds, setSeconds, clockIsRunning, setClockIsRunning };
};

export default useChatDuration;
