// useAdjustHeight.js
import { useEffect } from 'react';

const useAdjustHeight = (selector) => {
  useEffect(() => {
    const adjustHeight = () => {
      const element = document.querySelector(selector);
      if (element) {
        element.style.height = `${window.innerHeight}px`;
      }
    };

    adjustHeight();
    window.addEventListener('resize', adjustHeight);
    window.addEventListener('orientationchange', adjustHeight);

    return () => {
      window.removeEventListener('resize', adjustHeight);
      window.removeEventListener('orientationchange', adjustHeight);
    };
  }, [selector]);
};

export default useAdjustHeight;
