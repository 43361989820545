import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import handleKeyDown from '../../utils/handleKeyDown';
import axios from 'axios';
import '../../styles/css/ChatPage.scss';
import { Button, PictogramButton, Textarea } from '@amboss/design-system';
import UserContext from '../../context/UserContext';

function MessageInput({
  handleAbort,
  abortControllerRef,
  initialMessage,
  setMessages,
  messages,
  selectedChatUuid,
  setSelectedChatUuid,
  isLoadingResponse,
  setIsLoadingResponse,
  isAtBottom,
  scrollToBottom,
  betaModel,
}) {
  const [newMessage, setNewMessage] = useState('');
  const textAreaRef = useRef(null);
  const [inputKey, setInputKey] = useState(Date.now());

  const [isRecording, setIsRecording] = useState(false);
  const [isProcessingRecording, setIsProcessingRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const { mediCoachUser } = useContext(UserContext);

  // Start recording audio
  const startRecording = async () => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };
        mediaRecorderRef.current.start();
        setIsRecording(true);
      } else {
        throw new Error('MediaDevices API is not available.');
      }
    } catch (error) {
      console.error('Error accessing the microphone:', error.message);
      setIsRecording(false);
    }
  };

  // Stop recording audio and send it to OpenAI for transcription
  const stopRecording = () => {
    return new Promise((resolve) => {
      mediaRecorderRef.current.onstop = resolve; // Resolve promise when MediaRecorder stops
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
    }).then(() => {
      setIsRecording(false);
      sendAudioToOpenAI();
    });
  };

  // Send audio to OpenAI for transcription
  const sendAudioToOpenAI = async () => {
    setIsProcessingRecording(true);
    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
    audioChunksRef.current = [];

    try {
      const response = await axios.post('/api/openai/transcribe', audioBlob, {
        headers: {
          'Content-Type': 'audio/webm',
        },
      });

      const transcription = response.data;
      setNewMessage(transcription.text);
      await handleSend({ betaModel });
    } catch (error) {
      console.error('Error sending audio to backend:', error.message);
    } finally {
      setIsProcessingRecording(false);
    }
  };

  // Function to send a new message
  const handleSend = useCallback(async () => {
    if (newMessage.trim()) {
      setIsLoadingResponse(true);
      setMessages((prevMessages) => {
        return [
          ...prevMessages,
          { content: newMessage, role: 'user', createdAt: new Date() },
        ];
      });

      const apiUrl =
        process.env.NODE_ENV === 'production'
          ? 'https://m3ai.kniggi.com/api/chat'
          : 'http://localhost:3001/api/chat';

      abortControllerRef.current = new AbortController();

      try {
        const body = {
          message: newMessage,
          uuid: selectedChatUuid,
          userId: mediCoachUser,
          betaModel: betaModel,
        };

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'text/event-stream',
          },
          signal: abortControllerRef.current.signal,
          body: JSON.stringify(body),
        });

        const result = []; // Store streamed data here
        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

        // eslint-disable-next-line no-constant-condition
        let done, value;
        while (!done) {
          ({ done, value } = await reader.read());
          if (done) {
            setIsLoadingResponse(false);
            break;
          }
          scrollToBottom();
          const responses = value.split('\n\n').filter(Boolean);
          responses.forEach((response) => {
            const json = response.replace('data: ', '');
            const data = JSON.parse(json);

            // Listen for done/error events from the server
            if (data.done) {
              setSelectedChatUuid(data.uuid);
              setIsLoadingResponse(false);
            } else if (data.error) {
              console.error(data.error);
              handleAbort();
            } else if (data.data) {
              result.push(data.data);

              setMessages((prevMessages) => {
                const lastMessageIsAssistant =
                  prevMessages.length > 0 &&
                  prevMessages[prevMessages.length - 1].role === 'assistant';
                const newMessageObject = {
                  content: result.join(''),
                  role: 'assistant',
                  createdAt: new Date().toISOString(),
                };

                if (lastMessageIsAssistant) {
                  // If last message is from assistant, replace it
                  return [...prevMessages.slice(0, -1), newMessageObject];
                } else {
                  // If last message is not from assistant, append new message
                  return [...prevMessages, newMessageObject];
                }
              });
            }
          });
        }

        setInputKey(Date.now());
        setNewMessage('');
      } catch (error) {
        console.error('Error in handleSend:', error.message);
        handleAbort();
      } finally {
        setIsLoadingResponse(false);
      }
    }
  });

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [textAreaRef.current]);

  // Effect hook to attach the onKeyDown event listener
  useEffect(() => {
    const textAreaElement = textAreaRef.current;

    const onKeyDown = (event) => {
      // Your existing onKeyDown logic, or call handleKeyDown
      handleKeyDown(event, handleSend);
    };

    if (textAreaElement) {
      textAreaElement.addEventListener('keydown', onKeyDown);
    }

    // Cleanup function to remove the event listener
    return () => {
      if (textAreaElement) {
        textAreaElement.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [handleSend]);

  // Automatically setNewMessage and send it if the initial message is set
  useEffect(() => {
    if (!selectedChatUuid && initialMessage) {
      setNewMessage(initialMessage);
    }
  }, [initialMessage, selectedChatUuid, setNewMessage]);

  useEffect(() => {
    if (newMessage && newMessage === initialMessage && messages.length === 0) {
      handleSend({ betaModel }).catch((err) => console.error(err.message));
    }
  }, [newMessage]);

  return (
    <div className="message-input-container">
      {isAtBottom ? null : (
        <PictogramButton
          icon={'chevron-down'}
          variant={'secondary'}
          onClick={scrollToBottom}
          style={{ width: '2.5rem', height: '2.5rem' }}
          className={`scroll-to-bottom`}
        />
      )}
      <div className="message-input">
        <Textarea
          key={inputKey}
          ref={textAreaRef}
          value={newMessage}
          tabIndex={0}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Paste or Type your message..."
          disabled={isLoadingResponse}
          name="message-input"
        />
        <>
          {' '}
          {/* Conditionally rendered button */}
          {!newMessage && (
            <Button
              onClick={isRecording ? stopRecording : startRecording}
              destructive={isProcessingRecording}
              variant={isRecording ? 'secondary' : 'primary'}
            >
              {isRecording
                ? 'Stop Recording'
                : isProcessingRecording
                  ? 'Wait ...'
                  : 'Record'}
            </Button>
          )}
          {newMessage && (
            <Button
              variant="primary"
              destructive={isLoadingResponse}
              disabled={!isLoadingResponse && !newMessage.trim()}
              onClick={isLoadingResponse ? handleAbort : () => handleSend()}
            >
              {isLoadingResponse ? 'Cancel' : 'Send'}
            </Button>
          )}
        </>
      </div>
    </div>
  );
}

export default MessageInput;
