import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/css/index.scss';
import App from './App';
import reportWebVitals from './tests/reportWebVitals';
import { WebSocketProvider } from './context/WebSocketContext';
import { UserProvider } from './context/UserContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      <WebSocketProvider>
        <App />
      </WebSocketProvider>
    </UserProvider>
  </React.StrictMode>
);

reportWebVitals();
